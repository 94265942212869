import React from 'react';
import ComponentLnd20Profile from '../../components/lnd20Profile';
import img from '../../img/lnd20Profile2';
import * as REDIRECT from '../../constants/redirect';

export default () => {
    return (
        <ComponentLnd20Profile
            backgroundImage={img.images['right_bg.jpg']}
            images={img?.images}
            profileName={'Tatiana'}
            profileDescription={'I am a very open and sincere person who believes that real and mutual love really exists in the world. Moreover, I try to notice only positive things, give other people my beautiful smile and bright emotions.'}
            redirectLink={REDIRECT.SOFIA_LND20P2}
        />
    );
};