import axios from 'axios';
import {parseUrlParameter, setLocalStorage} from "../methods";
import * as SOCIAL from '../../constants/social';

export default class LoginApi {
    tokenLogin(token) {
        setLocalStorage('token', JSON.stringify(token));
        window.location.href = `/chat?registration=success&${window.location.search.substring()}`;
    }

    getCheckCountIp() {
        return axios
            .get(`${SOCIAL.PATH_MAIN_DOMAIN ? SOCIAL.PATH_MAIN_DOMAIN : ''}/api/login/checkCountIp`)
            .then(res => res && res.data)
    }

    checkEmailAPI(email) {
        return axios
            .post(`${SOCIAL.PATH_MAIN_DOMAIN ? SOCIAL.PATH_MAIN_DOMAIN : ''}/api/login/checkEmail`, {email: email})
            .then(res => res && res.data);
    }

    postRegister(email, password, day = 1, month = 1, year = 1930, gender = 1, user_real_name = '', body_type = 0, age_from = 18, age_to = 100, purpose_staying = 0) {
        const {tail, click_id, clickid} = parseUrlParameter();

        return axios
            .post(`${SOCIAL.PATH_MAIN_DOMAIN ? SOCIAL.PATH_MAIN_DOMAIN : ''}/api/v3/auth/registerLanding`, {
                email,
                password,
                day,
                month,
                year,
                gender,
                user_real_name,
                body_type,
                age_from,
                age_to,
                purpose_staying,
                clickid,
                click_id,
                tail
            })
            .then(res => {
                if(res && res.data) return {...res.data, ...{tail}}
            });
    }
}